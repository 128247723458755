
/* eslint-disable @typescript-eslint/camelcase */
import {defineComponent, onMounted, reactive, ref} from "vue";
import HelpCategoriesDropdown from "@/components/dropdown/HelpCategoriesDropdown.vue";
import {useRoute, useRouter} from "vue-router";
import { helpArticle } from "./types";
import {useStore} from "vuex";
import Swal from "sweetalert2";
import tinyMceEditor from "@/components/help/misc/tinyMceEditor.vue";
import {useAbility} from "@casl/vue";

export default defineComponent({
  name: "create-article-tab",
  components: {
    HelpCategoriesDropdown,
    tinyMceEditor
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const isSaving = ref<boolean>(false);
    const article = reactive({} as helpArticle);
    const returnToHelpSection = () => {
      router.push('/help');
    }
    onMounted(() => {
      if(route.params.id) {
        article.category_id = parseInt(route.params.id as string);
      }
    })
    const handleSaveButton = () => {
      isSaving.value = true;
      store.dispatch("HelpModule/createArticle", article)
          .then(() => {
            isSaving.value = false;
            returnToHelpSection();
          })
          .catch(err => {
            isSaving.value = false;
            Swal.fire({
              toast: true,
              position: "top-right",
              showConfirmButton: false,
              showCancelButton: false,
              timer: 800,
              icon: "error",
              title: "Error while saving article"
            })
          });
    }
    return {
      returnToHelpSection,
      handleSaveButton,
      article,
      isSaving
    }
  }
});
