
import {computed, defineComponent, onMounted, ref} from "vue";
import {useStore} from "vuex";
import {helpCategory} from "@/components/help/types";

export default defineComponent({
  name: "activities-types-dropdown",
  setup() {
    const store = useStore();
    const value = ref();
    const helpCategories = computed<helpCategory[]>(
        () => store.getters['ListsModule/getHelpCategoriesList']
    );
    onMounted(() => {
      store.dispatch("ListsModule/fetchHelpCategories")
    })
    return {
      value,
      helpCategories
    }
  }
});
