
import { defineComponent } from "vue";
import Editor from "@tinymce/tinymce-vue";
import {tinyMCEdevKey} from "./helpers";

export default defineComponent({
  name: "tiny-mce-editor",
  components: {
    editor: Editor
  },
  setup() {
    const editorConfig = {
      height: 400,
      menubar: false,
    }
    function tinyMCEKey() {
      return process.env.TINY_MCE_KEY ? process.env.TINY_MCE_KEY : tinyMCEdevKey
    }
    return{
      tinyMCEKey,
      editorConfig
    }
  }
});
